import React, { PureComponent } from 'react'
import styles from './styles.module.scss'
import cn from 'classnames'

class ConfidenceButton extends PureComponent {
  render() {
    const emojiClass = cn(
      {
        [styles.selected]: this.props.selected
      },
      [styles.emoji]
    )

    return (
      <span className={styles.button} onClick={this.onClick}>
        <span className={emojiClass}>{this.props.content}</span>
      </span>
    )
  }

  onClick = (e) => this.props.onClick(this.props.value)
}

ConfidenceButton.defaultProps = {
  onClick: () => {}
}

export default class ConfidenceScale extends PureComponent {
  render() {
    const { value, levels } = this.props
    const scale = levels.map((level) => {
      return (
        <ConfidenceButton
          key={level.value}
          content={level.label}
          value={level.value}
          selected={value === level.value}
          onClick={this.onChange}
        />
      )
    })
    return (
      <div className='react-confidence-scale d-flex justify-content-around w-100'>
        {scale}
      </div>
    )
  }

  onChange = (value) => {
    this.props.onChange(value)
  }
}

ConfidenceScale.defaultProps = {
  levels: [
    { label: '🤨', value: 0.5 },
    { label: '🧐', value: 0.75 },
    { label: '🤩', value: 1.0 }
  ]
}
